<template>
  <div id="app" :class="$route.name">
    <!--
    Even when routes use the same component, treat them
    as distinct and create the component again.
    -->
    <RouterView :key="$route.fullPath"/>
  </div>
</template>

<!-- This should generally be the only global CSS in the app. -->
<style lang="scss">
// Allow element/type selectors, because this is global CSS.
// stylelint-disable selector-max-type, selector-class-pattern


// Normalize default styles across browsers,
// https://necolas.github.io/normalize.css/
@import '~normalize.css/normalize.css';
// Style loading bar between pages.
// https://github.com/rstacruz/nprogress
@import '~nprogress/nprogress.css';


// https://buefy.org/documentation/start
@import '@design';
@import '@src/design/global.scss';
@import '@src/design/app.scss';

.fp-auto-height.fp-section, .fp-auto-height .fp-slide, .fp-auto-height .fp-tableCell {
    height: auto !important;
}
</style>
<script>
import { Trans } from '@utils/translation'

export default {
  name: "app",
  mounted() {
    this.initLanguage();
  },
  methods: {
    /**
     * 根據使用者偏好變更語言
     */
    initLanguage() {
      this.$store
        .dispatch('set/setLanguage', {
          language: localStorage.getItem('lang')? localStorage.getItem('lang'):Trans.getUserSupportedLang(),
        })
    },
  },
};
</script>
