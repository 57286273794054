const name = 'fade-up';

const directive = {
  bind(el, binding, vNode) {


    if ('IntersectionObserver' in window ||
      'IntersectionObserverEntry' in window ||
      'intersectionRatio' in window.IntersectionObserverEntry.prototype) {
      const fadeHideClass = 'fadeHide';

      addClass(el, fadeHideClass);

      setTimeout(() => {
        const { value } = binding;
        const options = {
          root: null,
          rootMargin: '0px',
          threshold: 0.1,
        };
        const handlerCallback = (entries) => {
          const firstEntry = entries[0];
          if (firstEntry.isIntersecting) {
            _isIntersecting();
          }
        };

        let delay = '';

        let fadeInClass;

        if (value) {
          delay = value.delay || '';
        }

        if (delay) {
          fadeInClass = 'fadeUpInAnimate';
        } else {
          fadeInClass = 'fadeUpIn';
        }

        if (delay) {
          addClass(el, `delay-${delay}`);
        }

        function _isIntersecting() {
          el.__observer__.disconnect();
          removeClass(el, fadeHideClass);
          addClass(el, fadeInClass);

          setTimeout(() => {
            removeClass(el, fadeInClass);
          }, delay ? ((delay * 100) + 2000) : 2000);
        }


        el.__observer__ = new IntersectionObserver(handlerCallback, options);
        el.__observer__.observe(el);
      }, 100);
    }
    function addClass(el, cls) {
      const el_class = el.className;

      if (el_class.indexOf(cls) < 0) {
        const blank = el_class != '' ? ' ' : '';
        const added = el_class + blank + cls;
        el.className = added;
      }
    }

    function removeClass(el, cls) {
      let el_class = ` ${el.className} `;
      el_class = el_class.replace(/(\s+)/gi, ' ');

      if (el_class.indexOf(cls) >= 0) {
        let removed = el_class.replace(` ${cls} `, ' ');
        removed = removed.replace(/(^\s+)|(\s+$)/g, '');
        el.className = removed;
      }
    }

  },
  unbind(el, binding) {
    el.__observer__.disconnect();
    el.__observer__ = null;
  },
};

export { name, directive };
