<script>
import Layout from '@layouts/main'

export default {
  /*
  //vue-meta
  page: {
    title: '404',
    meta: [{ name: 'description', content: '404' }],
  },*/
  components: { Layout },
  props: {
    resource: {
      type: String,
      default: '',
    },
  },
}
</script>

<template>
  <Layout>
    <section class="hero is-light">
      <div class="hero-body">
        <div class="containe has-text-centeredr">
          <h1 class="title">404</h1>
          <h2 class="subtitle">
            <template v-if="resource">{{ resource }}</template> Not Found
          </h2>
        </div>
      </div>
    </section>
  </Layout>
</template>
