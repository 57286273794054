import axios from 'axios';
axios.defaults.baseURL = '/api';

export const state = {};

export const getters = {};

export const mutations = {};

export const actions = {
  /*
  * 取得模組
  * */
  fetchModule({ commit }, {moduleAlias}) {
    return axios.get(`/module/${moduleAlias}`)
      .then((response) => {
        return response.data.data.items.items;
      });
  },
};
