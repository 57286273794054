import store from '@state/store';

export default [
  {
    path: '/:lang',
    name: 'home',
    component: () => lazyLoadView(import('@views/home')),
  },
  {
    path: '/:lang/about',
    name: 'about',
    component: () => lazyLoadView(import('@views/about')),
    meta: {
      async beforeResolve(routeTo, routeFrom, next) {
        let currentTimeLineModule = {};
        const lang = routeTo.params.lang;
        let timelineAlias = 'orange-timeline'
        if(lang !== "zh-TW" && lang !== "zh-Hant") {
          timelineAlias += "-en"
        }

        try {
          // 取得timeline模組
          [currentTimeLineModule] = await Promise.all([
            store.dispatch('module/fetchModule', {
              moduleAlias: timelineAlias
            }),
          ]);

          routeTo.params.timeLineModule = currentTimeLineModule;
        } catch (error) {
          next({ name: '404' })
        }

        next()
      },
    },
    props: (route) => ({
      timeLineModule: route.params.timeLineModule || {}
    }),
  },
  {
    path: '/:lang/news',
    name: 'news',
    component: () => lazyLoadView(import('@views/news')),
    meta: {
      async beforeResolve(routeTo, routeFrom, next) {
        let currentCategory = [];
        let categoryAliasList = [];
        let currentItem = {};

        try {
          // 取得分類
          [currentCategory] = await Promise.all([
            store.dispatch('item/fetchCategoryList'),
          ]);

          // 取得分類alias
          categoryAliasList = currentCategory.map( (item) => {
            return item['alias'];
          });

          // 取得新訊列表
          [currentItem] = await Promise.all([
            store.dispatch('item/fetchItemList', {
              categoryAlias: categoryAliasList,
              year: routeTo.query.year,
              month: routeTo.query.month,
            }),
          ]);

          routeTo.params.category = currentCategory;
          routeTo.params.filter = currentItem.filter;
          routeTo.params.items = currentItem.items;
          routeTo.params.pagination = currentItem.pagination;
        } catch (error) {
          next({ name: '404' })
        }

        next()
      },
    },
    props: (route) => ({
      filter: route.params.filter || [],
      category: route.params.category || [],
      items: route.params.items || [],
      pagination: route.params.pagination || {},
    }),
  },
  {
    path: '/:lang/news/:categoryAlias',
    name: 'news category',
    component: () => lazyLoadView(import('@views/news')),
    meta: {
      async beforeResolve(routeTo, routeFrom, next) {
        let currentCategory = [];
        let currentItem = {};

        try {
          // 取得分類
          [currentCategory] = await Promise.all([
            store.dispatch('item/fetchCategoryList'),
          ]);

          // 取得新訊列表
          [currentItem] = await Promise.all([
            store.dispatch('item/fetchItemList', {
              categoryAlias: [routeTo.params.categoryAlias],
              year: routeTo.query.year,
              month: routeTo.query.month,
            }),
          ]);

          routeTo.params.category = currentCategory;
          routeTo.params.filter = currentItem.filter;
          routeTo.params.items = currentItem.items;
          routeTo.params.pagination = currentItem.pagination;
        } catch (error) {
          next({ name: '404' })
        }

        next()
      },
    },
    props: (route) => ({
      filter: route.params.filter || [],
      category: route.params.category || [],
      items: route.params.items || [],
      pagination: route.params.pagination || {},
    }),
  },
  {
    path: '/:lang/tag/:tagTitle',
    name: 'news tag',
    component: () => lazyLoadView(import('@views/news')),
    meta: {
      async beforeResolve(routeTo, routeFrom, next) {
        let currentCategory = [];
        let categoryAliasList = [];
        let currentItem = {};

        try {
          // 取得分類
          [currentCategory] = await Promise.all([
            store.dispatch('item/fetchCategoryList'),
          ]);

          // 取得分類alias
          categoryAliasList = currentCategory.map( (item) => {
            return item['alias'];
          });

          // 取得新訊列表(標籤)
          [currentItem] = await Promise.all([
            store.dispatch('item/fetchItemList', {
              categoryAlias: categoryAliasList,
              tag: routeTo.params.tagTitle,
            }),
          ]);

          routeTo.params.category = currentCategory;
          routeTo.params.filter = currentItem.filter;
          routeTo.params.items = currentItem.items;
          routeTo.params.pagination = currentItem.pagination;
        } catch (error) {
          next({ name: '404' })
        }

        next()
      },
    },
    props: (route) => ({
      filter: route.params.filter || [],
      category: route.params.category || [],
      items: route.params.items || [],
      pagination: route.params.pagination || {},
    }),
  },
  {
    path: '/:lang/news/:categoryAlias/:itemAlias',
    name: 'news item',
    component: () => lazyLoadView(import('@views/newsItem')),
    meta: {
      async beforeResolve(routeTo, routeFrom, next) {
        let currentCategory = [];
        let currentItem = {};

        try {
          // 取得分類, 文章內容
          [currentCategory, currentItem] = await Promise.all([
            store.dispatch('item/fetchCategoryList'),
            store.dispatch('item/fetchItem', {
              itemAlias: routeTo.params.itemAlias
            }),
          ]);

          routeTo.params.category = currentCategory;
          routeTo.params.item = currentItem;
        } catch (error) {
          next({ name: '404' })
        }
        next()
      },
    },
    props: (route) => ({
      category: route.params.category || [],
      item: route.params.item || [],
    }),
  },
  {
    path: '/:lang/brand/:brandAlias',
    name: 'brand',
    component: () => lazyLoadView(import('@views/brand')),
    meta: {
      beforeResolve: async function (routeTo, routeFrom, next) {
        let currentBrandData = {};

        try {
          // 取得品牌內容
          [currentBrandData] = await Promise.all([
            store.dispatch('item/fetchItem', {
              itemAlias: routeTo.params.brandAlias
            }),
          ]);

          routeTo.params.brandData = currentBrandData;
        } catch (error) {
          next({ name: '404' })
        }

        next()
      },
    },
    props: (route) => ({
      brandData: route.params.brandData || {},
    }),
  },
  {
    path: '/:lang/team',
    name: 'team',
    component: () => lazyLoadView(import('@views/team')),
  },
  {
    path: '/:lang/contact',
    name: 'contact',
    component: () => lazyLoadView(import('@views/contact')),
  },
  {
    path: '/404',
    name: '404',
    component: require('@views/_404').default,
    props: true,
  },
  {
    path: '*',
    redirect: '/404',
  },
]

function lazyLoadView(AsyncView) {
  const AsyncHandler = () => ({
    component: AsyncView,
    loading: require('@views/_loading').default,
    delay: 400,
    error: require('@views/_timeout').default,
    timeout: 10000,
  });

  return Promise.resolve({
    functional: true,
    render(h, { data, children }) {
      return h(AsyncHandler, data, children)
    },
  })
}
