import { DEFAULT_LANGUAGE } from '@src/lang/config';
import { Trans } from '@utils/translation';

const getWebsite = () => {
  const url = window.location.hostname;

  switch (url) {
    case 'orangeshabu2019.daydream-lab.com':
      return {
        main: 'http://orangeshabu2019.daydream-lab.com',
        shabu: 'http://orangeshabushabu2019.daydream-lab.com',
        extension: 'http://extension1byorange2019.daydream-lab.com',
      };
      break;
    case 'orange.test':
      return {
        main: 'http://orange.test',
        shabu: 'http://shabu.test',
        extension: 'http://extension.test',
      };
      break;
    case 'orangeshabu.com':
      return {
        main: 'https://orangeshabu.com',
        shabu: 'https://orangeshabushabu.com',
        extension: 'https://extension1byorange.com',
      };
      break;
    default:
      return {
        main: 'http://orangeshabu2019.daydream-lab.com',
        shabu: 'http://orangeshabushabu2019.daydream-lab.com',
        extension: 'http://extension1byorange2019.daydream-lab.com',
      };
  }
};

export const state = {
  cachedLanguage: DEFAULT_LANGUAGE,
  cachedWebsite: getWebsite(),
};

export const getters = {};

export const mutations = {
  CACHE_LANGUAGE(state, currentLanguage) {
    state.cachedLanguage = currentLanguage
  },
};

export const actions = {
  /*
  * 設定語言
  * */
  setLanguage({ commit }, {language}) {
    return new Promise((resolve, reject) => {
      try {
        Trans.changeLanguage(language);
        commit('CACHE_LANGUAGE', language);
        resolve()
      }catch (e) {
        reject(new Error(e));
      }
    })
  },
};
