const name = 'lazy-load';

const directive = {
    inserted: (el, binding) => {
        function loadImage() {
            const imageElement = el;

            if (imageElement) {
                if (binding.arg === "bg") {
                    imageElement.style.backgroundImage = `url(${
                        imageElement.dataset.url
                        })`;
                } else {
                    imageElement.addEventListener("load", () => {
                        setTimeout(() => el.classList.add("loaded"), 100);
                    });
                    imageElement.addEventListener("error", () => console.log("error"));
                    imageElement.src = imageElement.dataset.url;
                }
            }
        }

        function handleIntersect(entries, observer) {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    loadImage();
                    observer.unobserve(el);
                }
            });
        }

        function createObserver() {
            const options = {
                root: null,
                rootMargin: "0px 0px 400px 0px",
                threshold: 0
            };
            const observer = new IntersectionObserver(handleIntersect, options);
            observer.observe(el);
        }
        if (window["IntersectionObserver"]) {
            createObserver();
        } else {
            loadImage();
        }
    }
}

export { name, directive }
