export function getScrollTop() {
  let scrollTop = 0;

  if (typeof window.pageYOffset !== 'undefined') {
    scrollTop = window.pageYOffset;
  } else if (typeof document.compatMode !== 'undefined' && document.compatMode !== 'BackCompat') {
    scrollTop = document.documentElement.scrollTop;
  } else if (typeof document.body !== 'undefined') {
    scrollTop = document.body.scrollTop;
  }

  return scrollTop;
}

export function scrollTo(value, offset = 0) {
  const sectionTop = typeof value === 'string'
    ? document.querySelector(`.${value}`).offsetTop + offset
    : value + offset;

  window.scrollTo(0, sectionTop);
}
