import axios from 'axios';
import i18n from '@lang'
axios.defaults.baseURL = '/api';

export const state = {};

export const getters = {};

export const mutations = {};

export const actions = {
  /*
  * 取得分類
  * */
  fetchCategoryList({ commit }) {
    const lang = localStorage.getItem("lang")? localStorage.getItem("lang"):i18n.locale
    let alias = "orange-news"
    if(lang !== "zh-TW" && lang !== "zh-Hant") {
      alias += "-en"
    }
    return axios.get(`/module/${alias}/subcategories`)
      .then((response) => {
        return response.data.data.items;
      });
  },
  /*
  * 取得新訊列表
  * */
  fetchItemList({ commit }, {
    tag, categoryAlias, year, month,
    page = 1,
    limit = 6,
    orderBy = 'featured',
    order = 'desc'
  }) {
    return axios.post(`/item/search`, {
      tag: tag,
      category_alias: categoryAlias,
      year: year,
      month: month,
      page: page,
      limit: limit,
      order_by: orderBy,
      order: order
    }).then((response) => {
      return response.data.data;
    });
  },
  /*
  * 取得單一新訊內容
  * */
  fetchItem({ commit }, {itemAlias}) {
    return axios.get(`/item/${itemAlias}`).then((response) => {
      return response.data.data.items;
    });
  },
};
