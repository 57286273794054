<script>
import TheHeader from './TheHeader'
import TheFooter from './TheFooter'

export default {
  components: { TheHeader, TheFooter },
}
</script>

<template>
  <div>
    <TheHeader/>
    <slot/>
    <TheFooter/>
  </div>
</template>


