import Vue from 'vue';
import VueI18n from 'vue-i18n';
Vue.use(VueI18n);

// 引入 language 預設數值
import { DEFAULT_LANGUAGE, FALLBACK_LANGUAGE } from '@src/lang/config';

// 引入自定義翻譯檔
import enLocale from './en/';
import zhTWLocale from './zh-TW/';

// 合併 Element-UI 與自定義翻譯檔
let messages;
messages = {
  en: enLocale,
  'zh-TW': zhTWLocale,
};

const i18n = new VueI18n({
	locale: DEFAULT_LANGUAGE, // set locale
	fallbackLocale: FALLBACK_LANGUAGE,
	messages // set locale messages
});

export default i18n;
