const files = require.context('./', false, /[A-Za-z0-9-_,\s]+\.json$/i);
const locales = {};
files.keys().forEach(key => {
	const matched = key.match(/([A-Za-z0-9-_]+)\./i)
	if (matched && matched.length > 1) {
		const file_name = matched[1]
		locales[file_name] = files(key)
	}
})

export default locales;
