<script>
import { setComputed } from '@state/helpers'

export default {
  props: {
    list: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...setComputed
  }
}
</script>
<template>
  <div :class="$style.breadcrumb">
    <template v-for="(breadcrumbItem) in list">
      <router-link
        v-if="breadcrumbItem.alias"
        :to="{ name: breadcrumbItem.alias, params: breadcrumbItem.params }"
        :class="$style.breadcrumbItem"
        :key="breadcrumbItem.title">
        {{breadcrumbItem.title}}
      </router-link>
      <span
        v-else
        :key="breadcrumbItem.title"
        :class="$style.breadcrumbItem">
                {{ breadcrumbItem.title }}
              </span>
    </template>
  </div>
</template>
<style lang="scss" module>
  .breadcrumb {
    .breadcrumbItem {
      font-family: PingFangTC-Light, Noto Sans TC, sans-serif;
      @include font(14px, 20px, 1.12px, null);
      color: #000;
      &:not(:last-child):after {
        content: '/';
      }
    }
  }
</style>
