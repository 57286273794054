import Vue from 'vue'

const files = require.context("./", false, /^(?!.*(?:index.js$)).*\.js$/i);

files.keys().forEach(key => {
    Vue.directive(
        files(key).name,
        files(key).directive
    );
});
