<script>
import { setComputed } from '@state/helpers'

import { getScrollTop } from '@utils/scroll'

import axios from "axios";

export default {
  data() {
    return {
      hostname: `${location.protocol}//${window.location.hostname}`,
      menuList: [
        {
          title: this.$i18n.t("global.NAV_ABOUT"),
          alias: "about",
          hidden: 0,
          disabled: 0
        },
        {
          title: this.$i18n.t("global.NAV_NEWS"),
          alias: "news",
          hidden: 0,
          disabled: 0
        },
        {
          title: this.$i18n.t("global.NAV_BRAND"),
          type: "mega",
          active_alias: 'brand',
          hidden: 0,
          disabled: 0,
          children: []
        },
        {
          "title": this.$i18n.t("global.NAV_TEAM"),
          "alias": "team",
          hidden: 0,
          disabled: 0
        },
        {
          "title": this.$i18n.t("global.NAV_CONTACT"),
          "alias": "contact",
          hidden: 0,
          disabled: 0
        }
      ],
      showHamber: false,
      showMega: false,
      scrollTop: this.getScrollTop,
      megaLoading: false

    }
  },
  computed: {
    ...setComputed,
  },
  destroyed() {
    document.removeEventListener("scroll", this.onScroll, { passive: false });
  },
  mounted() {
    this.setBranchMenu()
    document.addEventListener("scroll", this.onScroll, { passive: false });
  },
  methods: {
    setLanguage(lang) {
      const tempCurrentLanguage = this.currentLanguage
      this.$store
        .dispatch('set/setLanguage', {
          language: lang
      })
      if(lang !== tempCurrentLanguage){
        localStorage.setItem('lang', lang)
        window.location = '/'+lang
      }
    },
    formatLanguage(lang) {
      const langList = {
        'en': 'en',
        'zh-TW': '中'
      };

      return langList[lang]
    },
    noLink() {

    },
    onScroll() {
      this.scrollTop = this.getScrollTop()
    },
    setBranchMenu() {
      // 目前寫法僅品牌會展開子選單 20240611
      let aliasList = [];
      this.megaLoading = true;
      let alias = "orange-brand"
      if(this.$i18n.locale !== "zh-TW" && this.$i18n.locale !== "zh-Hant") {
        alias += "-en"
      }
      axios.get(`/module/${alias}/subcategories`).then((response) => {
        this.menuList.forEach((item, $index) => {
          if(item.title === '品牌' || item.title === 'Brand') {
            this.menuList[$index].children = response.data.data.items.map(menu=> {
              return {
                title: menu.title,
                hidden: 0,
                disabled: 0
              }
            });

            aliasList = response.data.data.items.map(menu=> {
              return menu.alias
            });

            this.setBranchSubMenu(aliasList);
          }
        })
      });
    },
    setBranchSubMenu(aliasList) {
      axios.post(`/item/search`, {
        limit: 12,
        order: 'asc',
        category_alias: aliasList
      }).then((response) => {
        this.menuList.forEach((item, $index) => {
          if(item.title === '品牌' || item.title === 'Brand') {
            this.menuList[$index].children.forEach((item2, $index2) => {
              response.data.data.items.forEach((item3, $index3) => {
                if(item2.title === item3.category_title) {
                  this.menuList[$index].children[$index2].children =
                    response.data.data.items
                      .filter(filterItem=> {
                        return filterItem.category_title === item3.category_title
                      })
                      .map(menu2=> {
                        return {
                          title: menu2.title,
                          alias: "brand",
                          params: {
                            brandAlias: menu2.alias
                          },
                          hidden: 0,
                          disabled: 0
                        }
                      });
                }
              })
            })
          }
        })

        this.megaLoading = false
      });

    },
    getScrollTop
  },
  
}
</script>
<template>
  <header :class="[$style.header, { [$style.isScroll]: scrollTop > 70 }]">
    <nav :class="$style.navbar" class="navbar navbar-expand-lg align-items-end">
      <router-link :class="$style.navbarLeft" :to="{ name: 'home' }">
        <img :class="$style.logo" src="~@assets/images/logo.svg">
      </router-link>
      <div class="navbar-collapse" :class="[$style.navbarCollapse, { [$style.isShow]: showHamber }]">
        <ul :class="$style.navbarNav" class="navbar-nav m-auto text-center">
          <template v-for="(menuItem, index) in menuList">
            <!--Mega-->
            <li v-if="menuItem.type === 'mega'" :class="[$style.navItem, $style.megaDropdown, { [$style.isShow]: showMega }]" :key="index"
                class="nav-item">
              <span
                :class="[$style.navLink, { [$style.isActive]: $route.name === menuItem.active_alias }]"
                class="nav-link"
                @click="showMega = !showMega"
              >
                {{menuItem.title}}
              </span>
              <div :class="[$style.megaMenu]" v-if="!megaLoading">
                <div :class="$style.megaList">
                  <div :class="$style.megaItem" v-for="megaItem in menuItem.children" :key="megaItem.title">
                    <h6 :class="$style.megaTitle">{{megaItem.title}}</h6>
                    <hr>
                    <ul class="list-unstyled">
                      <li :class="$style.megaSubItem" v-for="megaSubItem in megaItem.children" :key="megaSubItem.title">
                        <router-link
                          :active-class="$style.isActive"
                          :disabled="megaSubItem.disabled"
                          :class="[$style.megaLink, { [$style.isDisable]: megaSubItem.disabled }]"
                          :to="{ name: megaSubItem.alias, params: megaSubItem.params }"
                        >
                          {{megaSubItem.title}}
                        </router-link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </li>
            <li v-else :class="$style.navItem" :key="index" class="nav-item">
              <router-link
                :class="$style.navLink"
                class="nav-link"
                :to="{ name: menuItem.alias }"
                :active-class="$style.isActive"
              >
                {{menuItem.title}}
              </router-link>
            </li>
          </template>
        </ul>
      </div>
      <div :class="$style.navRight" class="d-flex align-items-center justify-content-end">
        <!-- <button class="btn btn-outline-light isCircle my-2 my-sm-0" @click="setLanguage(currentLanguage === 'zh-TW' ? 'en' : 'zh-TW')">{{formatLanguage(currentLanguage)}}</button> -->
        <button :class="$style.navbarToggle" @click="showHamber = !showHamber">
          <img v-if="showHamber" src="@assets/images/icon/close.svg">
          <img v-else src="@assets/images/icon/hamburger.svg">
        </button>
      </div>
    </nav>
  </header>
</template>
<style lang="scss" module>
  $header-height: 130px;
  $header-height-isScroll: 97px;
  $header-height-mobile: 70px;

  $navbar-padding: 26px 44px;
  $navbar-padding-isScroll: 21px 44px;
  $navbar-padding-mobile: 0.5rem 1rem !important;
  $navbar-background: linear-gradient(to bottom,  rgba(0,0,0,1) 0%,rgba(29,20,7,1) 100%);

  $logo-height: 78px;
  $logo-height-isScroll: 55px;
  $logo-height-mobile: 54px !important;

  $hambuger-background: rgba(#241604, 0.95);

  $navLink-color: #fff;
  $navLink-color-isActive: #ad9d8b;
  $navLink-color-active: darken(#ad9d8b, 10%);

  $megaDropdown-background: rgba(#362b25, 0.95);
  $megaDropdown-background-mobile: #80715c;

  .header {
    transition: height 0.3s;
    height: $header-height;
    z-index: 2;
    position: relative;
    @include touch {
      height: $header-height-mobile;
    }
    &.isScroll {
      height: $header-height-isScroll;
      .navbar {
        padding: $navbar-padding-isScroll;
      }
      .navbarLeft, .navRight {
        height: $logo-height-isScroll;
        padding: 0;
        .logo {
          margin-top: 0px;
          height: $logo-height-isScroll;
          padding: 0;
        }
      }
      .megaDropdown {
        & > .megaMenu {
          top: $header-height-isScroll;
        }
      }
    }
  }
  .navbar {
    padding: $navbar-padding;
    background-image: $navbar-background;
    position: fixed;
    width: 100vw;
    @include touch {
      padding: $navbar-padding-mobile;
    }
    &:before {
      @include bg-image-mask($navbar-background, 0);
    }
  }
  .navbarLeft, .navRight {
    position: relative;
    z-index: 1;
    width: $logo-height;
    height: $logo-height;
    padding: 0;
    transition: height 0.3s;
    @include touch {
      height: $logo-height-mobile;
      padding: 0;
    }
    .logo {
      transition: height 0.3s;
      height: $logo-height;
      @include touch {
        height: $logo-height-mobile;
      }
    }
  }
  .navRight {
    transition: height 0.3s;
    @include touch {
      height: $logo-height-mobile;
    }
  }
  .navbarToggle {
    appearance: none;
    background: transparent;
    border: 0px solid transparent;
    color: #fff;
    position: relative;
    z-index: 1;
    font-size: 30px;
    padding: 0;
    width: 35px;
    text-align: center;
    line-height: 5px;
    opacity: 0;
    @include touch {
      opacity: 1;
    }
    &:focus {
      outline: none;
    }
  }
  .navbarCollapse {
    @include touch {
      pointer-events: none;
      position: absolute;
      top: $header-height-mobile;
      width: 100vw;
      left: 0;
      background: $hambuger-background;
      padding: 3rem 0rem;
      height: calc(100vh - #{$header-height-mobile});
      transition: transform 0.6s ease-in-out;
      transform: translateY(-100%);
      z-index: -1;
      overflow-y: scroll;
      overscroll-behavior: contain;
      .navbarNav {
        .navItem {
          opacity: 0;
        }
        .navItem:nth-child(1) {
          transition: opacity 0.3s linear;
        }
        .navItem:nth-child(2) {
          transition: opacity 0.3s linear;
        }
        .navItem:nth-child(3) {
          transition: opacity 0.3s linear;
        }
        .navItem:nth-child(4) {
          transition: opacity 0.3s linear;
        }
        .navItem:nth-child(5) {
          transition: opacity 0.3s linear;
        }
      }
      &.isShow {
        pointer-events: auto;
        opacity: 1;
        transform: translateY(0);
        .navbarNav {
          .navItem {
            opacity: 1;
          }
          .navItem:nth-child(1) {
            transition: opacity 0.3s linear 0.3s;
          }
          .navItem:nth-child(2) {
            transition: opacity 0.3s linear 0.4s;
          }
          .navItem:nth-child(3) {
            transition: opacity 0.3s linear 0.5s;
          }
          .navItem:nth-child(4) {
            transition: opacity 0.3s linear 0.6s;
          }
          .navItem:nth-child(5) {
            transition: opacity 0.3s linear 0.7s;
          }
        }
      }
    }

  }
  .navItem {
    &:hover {
      .navLink {
        color: $navLink-color;
      }
      .navLink:not(.isActive) {
        color: $navLink-color;
        @include touch {
          color: $navLink-color;
        }
        &:active {
          color: $navLink-color-active;
          @include touch {
            color: $navLink-color;
          }
        }
        &:before {
          content: '';
          position: absolute;
          bottom: 1.5rem;
          left: 50%;
          margin-left: -15px;
          width: 26px;
          height: 1px;
          background: $navLink-color-isActive;
          @include touch {
            display: none;
          }
        }
      }
    }
  }
  .navLink {
    padding-bottom: 33px;
    margin-bottom: -27px;
    padding-right: 0 !important;
    padding-left: 0 !important;
    margin-left: 40px;
    margin-right: 40px;
    color: $navLink-color;
    letter-spacing: 4.8px;
    position: relative;
    font-weight: 300;
    font-family: PingFangTC-Light, Noto Sans TC, sans-serif;
    line-height: 22px;
    @include touch {
      display: inline-block;
      font-size: 28px;
      text-align: center;
      margin-bottom: 0;
      padding-top: 1.5rem;
      font-weight: 300;
      letter-spacing: 7.84px;
      padding-right: 40px !important;
      padding-left: 40px !important;
    }
    &.isActive {
      &:before {
        content: '';
        position: absolute;
        bottom: 1.5rem;
        left: 50%;
        margin-left: -15px;
        width: 26px;
        height: 1px;
        background: $navLink-color-isActive;
        @include touch {
          bottom: 1rem;
        }
      }
    }
  }
  .megaDropdown {
    position: static;
    & > .megaMenu {
      transition: transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
      opacity: 0;
      position: absolute;
      background: $megaDropdown-background;
      left: 0;
      top: $header-height;
      width: 100%;
      padding: 3rem 3rem 60px;
      font-family: 'Noto Serif TC', serif;
      transform: translateY(-100%);
      z-index: -1;
      @include touch {
        position: static;
        transform: unset;
        display: none !important;
        background: $megaDropdown-background-mobile;
        padding: 40px 30px;
      }
      .megaList {
        display: flex;
        justify-content: center;
        @include touch {
          flex-direction: column;
        }
        .megaItem {
          text-align: center;
          color: #fff;
          width: 240px;
          margin: 0 5rem;
          hr {
            border-color: #fff;
            margin-bottom: 19px;
            @include touch {
              display: none;
            }
          }
          @include touch {
            margin: 0 auto;
            &:not(:last-child) {
              border-bottom: 1px solid #fff;
              padding-bottom: 20px;
              margin-bottom: 30px;
              width: 100%;
            }
          }
          .megaTitle {
            font-size: 16px;
            text-indent: 6px;
            letter-spacing: 6px;
            min-height: 38px;
            @include touch {
              font-size: 16px;
              line-height: 23px;
              letter-spacing: 6.4px;
              color: #362b25;
              font-weight: 600;
            }
          }
          .megaSubItem {
            padding: 0.4rem 0;
            font-weight: 400;
            letter-spacing: 2px;
            .megaLink {
              color: #fff;
              font-weight: 500;
              letter-spacing: 1.92px;
              &:hover {
                text-decoration: none;
                color: #ad9d8b;
              }
              &.isActive {
                color: #fff;
                @include touch {
                  color: #ad9d8b;
                }
              }
              &.isDisable {
                pointer-events: none;
                opacity: 0.5;
              }
            }
          }
        }
      }
    }
    &:hover {
      & > .megaMenu {
        transform: translateY(0%);
        opacity: 1;
        display: block;
      }
    }
    .navLink {
      @include touch {
        &:after {
          content: "";
          width: 18px;
          height: 10px;
          background-image: url(@assets/images/arrow_white.png);
          position: absolute;
          right: 1rem;
          top: 50%;
          background-position: center;
          margin-top: -8px;
        }
      }
    }
    &.isShow {
      & > .megaMenu {
        opacity: 1;
        display: block !important;
      }
      .navLink {
        @include touch {
          &:after {
            transform: scaleY(-1);
          }
        }
      }
    }
  }
</style>
