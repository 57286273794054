const name = 'line-bg';

const directive = {
    bind: function(el, binding, vNode) {
        setTimeout(()=>{
            const $el = el;

            let top = -0;

            const target = document.getElementById('app');

            el.__resizeObserver__ = new ResizeObserver(entries => {
                top = -0;
                top -= getOffsetTop($el);
                $el.style.backgroundPositionY =  top + 'px';
            });

            el.__resizeObserver__.observe(target);

            top -= getOffsetTop($el);

            $el.style.backgroundPositionY =  top + 'px';

            function getOffsetTop(el) {
                //var left = 0;
                var top = 0;
                let $el = el;

                while($el != document.body)
                {
                    //left = $el.offsetLeft;
                    top += $el.offsetTop;

                    $el = $el.offsetParent;
                }

                return top
            }
        },1000)
    },
    unbind: function(el, binding) {
        el.__resizeObserver__.disconnect();
        el.__resizeObserver__ = null;
    }
};

export { name, directive }


